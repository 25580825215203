import axios from 'axios';

const createAPI = (
  // baseURL = 'https://api.despectrajakarta.com/wp-json/panda/v1',
  baseURL = 'https://api.despectra.com/wp-json/wp/v2',
  config = {}
) => {
  axios.defaults.headers.post['Authorization'] =
    'Basic ZGVzcGVjdHJhY21zYWRtaW46d2UwQSBucTBXIDRCakIgZjd5OCBCdHl0IEY2TUs=';

  const axiosInstance = axios.create({
    baseURL,
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    ...config,
  });

  // setup axios.intercept
  return axiosInstance;
};

const api = createAPI();

export default api;
