import React, { useState } from 'react';
import { Carousel, Col, Icon, Row } from 'antd';

import Container from 'components/base/container';

import './style.scss';

const ROOM_LIST = [
  {
    name: 'Deluxe',
    capacity: 15,
    description:
      'Our Deluxe rooms offer 45 sq m of Karaoke space with avant-garde sound systems, lighting and comes with an exclusive access to a private washroom.',
  },
  {
    name: 'VIP',
    capacity: 20,
    description:
      'Spacious and cozy, our VIP rooms encompass 60 sq m of refined space, featuring avant-garde audio systems, lighting and an exclusive access to a private washroom.',
  },
  {
    name: 'Royale Suite',
    capacity: 30,
    description:
      'Our 80 sq m Royal Suite rooms are elegantly fitted with refined furnishings, and come with a spacious dining area overlooking the Karaoke sofas.',
  },
  {
    name: 'President Suite',
    capacity: 70,
    description:
      'Our two generously-sized President Suites occupy 150 and 200 sq m of refined space respectively. Each suite boasts a living, dining, bar area and a private luxurious bathroom.',
  },
];

export default function SectionRooms({ id, rooms }) {
  const [roomSelected, setRoomSelected] = useState('');

  const modified = [...rooms].map((room, index) => {
    const { acf = {} } = room || {};
    const { room_image = {}, room_name = '' } = acf;
    const { url = '' } = room_image;

    return { index, image: url, label: room_name };
  });

  function handleClick(name) {
    setRoomSelected(name);
  }

  const afterChangeCarousel = (index) => {
    const roomName = modified.find((el) => el.index === index);
    const { label = '' } = roomName || {};
    setRoomSelected(label.toLowerCase());
  };

  return (
    <section id={id} className="pd-home-rooms section">
      <Container>
        <Row>
          <Col xs={24}>
            <div className="title-2 pd-align-center gold">
              <strong>
                <u>ROOMS</u>
              </strong>
            </div>
          </Col>
        </Row>
        <Row gutter={[10, 40]}>
          {ROOM_LIST.map(
            ({ name = '', capacity = 0, description = '' }, index) => {
              return (
                <React.Fragment key={index}>
                  <Col xs={0} lg={6}>
                    <div
                      className={`pd-home-rooms-card ${
                        name.toLowerCase() === roomSelected &&
                        'pd-home-rooms-card-active'
                      }`}
                      onClick={() => handleClick(name.toLowerCase(), index)}>
                      <div className="user">
                        <Icon className="icon" type="user" />
                        <span className="user-count">{capacity}+</span>
                      </div>
                      <div className="title-3 gold">{name}</div>
                      <div className="description text-pre-line">
                        {description}
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} lg={0} className="pd-home-rooms-card-mobile">
                    <div
                      className="pd-home-rooms-card"
                      onClick={() => handleClick(name.toLowerCase(), index)}>
                      <div className="pd-home-rooms-image">
                        <div className="pd-home-rooms-image pd-margin-top-md"></div>
                      </div>
                      <div className="text-container">
                        <div className="room-thumbnail">
                          <div className="title-3 gold">{name}</div>
                          <div className="user">
                            <Icon className="icon" type="user" />
                            <span className="user-count">{capacity}+</span>
                          </div>
                        </div>
                        <div className="description">{description}</div>
                      </div>
                    </div>
                  </Col>
                </React.Fragment>
              );
            }
          )}

          <Col xs={24} lg={24}>
            <div className="pd-home-rooms-image pd-margin-top-md">
              <Carousel
                afterChange={afterChangeCarousel}
                lazyLoad
                autoplay
                arrows
                dots={false}>
                {modified.length > 0 &&
                  modified.map((room, index) => {
                    return (
                      <div key={index}>
                        <img src={room.image} alt="room" />
                      </div>
                    );
                  })}
              </Carousel>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

// <Carousel autoplay arrows dots={false}>
// {rooms.length > 0 &&
//   rooms.map((room, index) => {
//     const { acf = {} } = room || {};
//     const { room_image = {} } = acf;
//     const { url = '' } = room_image;
//     return (
//       <div key={index}>
//         <img src={url} />
//       </div>
//     );
//   })}
// </Carousel>   <Carousel autoplay arrows dots={false}>
// {room.room_images.map((roomImage, index) => (
// <div key={index} className="carousel-container">
//   <img src={roomImage} alt="Room" />
// </div>
// ))}
// </Carousel>
