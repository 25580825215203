import React, { useEffect, useState } from 'react';

import { PageSpinner } from 'components/base/page-spinner';

import { Header } from 'components/base/header';
import { Footer } from 'components/base/footer';

import HomeSectionBanners from 'components/blocks/Home/SectionBanners';
import HomeSectionHours from 'components/blocks/Home/SectionHours';
import SectionExperience from 'components/blocks/Home/SectionExperience';
import HomeSectionRooms from 'components/blocks/Home/SectionRooms';
import HomeSectionPremiumRoom from 'components/blocks/Home/SectionPremiumRoom';
import HomeSectionEventPromo from 'components/blocks/Home/SectionEventPromo';
import HomeSectionGalleries from 'components/blocks/Home/SectionGallery';
import HomeSectionHiring from 'components/blocks/Home/SectionHiring';

import SEO from 'components/seo';

import BannerApi from 'api/banner';
import EventApi from 'api/events';
import FooterApi from 'api/footerContent';
import GalleriesApi from 'api/galleries';
import CopyWriting from 'api/pageContent';
import RoomsApi from 'api/rooms';
import SeoApi from 'api/seo';
// import LanguageApi from 'api/language';
export function Home() {
  const [pageContent, setPageContent] = useState({
    copyWriting: {},
    event: [],
    footer: [],
    galleries: [],
    roomGalleries: [],
    rooms: [],
    seo: {},
  });
  const [loadingPage, setLoadingPage] = useState(true);
  const [loadingImages, setLoadingImages] = useState(true);

  useEffect(() => {
    async function getPageContent() {
      try {
        // Banner
        const bannerResponse = await BannerApi.getBanner();
        const banners = bannerResponse.data.map(
          ({ acf = {}, id = '', title = {} }) => {
            return { image: acf.image, id: id, caption: title.rendered };
          }
        );

        // Copy Writing
        let copyWriting = {};
        const copyWritingResponse = await CopyWriting.getPageContent();
        copyWritingResponse.data.forEach((eachCw) => {
          const { acf = {}, content = {}, title = {} } = eachCw;
          const { background_image = {} } = acf;
          const { url = '' } = background_image || {};
          return (copyWriting[title.rendered] = {
            content: content.rendered,
            image: url || '',
          });
        });

        // SEO
        let seoObj = {};
        const seoResponse = await SeoApi.getSEO();
        if (seoResponse.length) {
          seoObj.meta_header = seoResponse.data[0].title.rendered;
          seoObj.meta_description = seoResponse.data[0].content.rendered;
        }

        const roomsResponse = await RoomsApi.getRooms();

        const galleriesResponse = await GalleriesApi.getGalleries();
        const galleryArray = galleriesResponse.data.map(
          ({ acf = {}, title = {}, id = '' }) => {
            return { title: title.rendered, image: acf.image, id };
          }
        );

        const eventResponse = await EventApi.getEvents();
        const event = eventResponse.data.map(
          ({ id = '', title = {}, acf = {}, content = {} }) => {
            const {
              event_date_start = '',
              event_date_end = '',
              event_image = '',
              current = false,
            } = acf;
            return {
              id,
              title: title.rendered,
              startDate: event_date_start,
              endDate: event_date_end,
              image: event_image,
              content: content.rendered,
              highlight: current,
            };
          }
        );
        const footerContentResponse = await FooterApi.getFooterContent();

        setPageContent((prevState) => ({
          ...prevState,
          banners,
          event,
          footer: footerContentResponse.data[0].acf,
          galleries: galleryArray,
          rooms: roomsResponse.data,
          // roomGalleries: roomGalleriesResponse.data,
          copyWriting,
          seo: seoObj,
          logo: footerContentResponse.data[0].acf.website_logo,
        }));
      } finally {
        setLoadingPage(false);
      }
    }

    getPageContent();

    return () => {
      setLoadingPage(true);
      setPageContent({});
    };
  }, []);

  useEffect(() => {
    if (!loadingPage && !loadingImages) {
      setLoadingPage(false);
    }
  }, [loadingPage, loadingImages]);

  if (loadingPage) {
    return <PageSpinner />;
  }

  return (
    <PageSpinner loading={loadingPage}>
      <SEO
        titlePage={pageContent.seo.meta_header || 'KTV Lounge'}
        metaDescription={pageContent.seo.meta_description || 'KTV Lounge'}
      />
      <Header logo={pageContent.logo} />
      <div className="pd-home">
        <HomeSectionBanners
          id="home"
          banners={pageContent.banners}
          onImagesLoaded={() => setLoadingImages(false)}
        />
        <HomeSectionHours id="hours" content={pageContent.footer} />
        <SectionExperience
          id="experience"
          content={pageContent.copyWriting['About Us'] || {}}
        />
        <HomeSectionRooms id="rooms" rooms={pageContent.rooms} />
        <HomeSectionPremiumRoom
          id="premium-lounge"
          content={pageContent.copyWriting['Premium Lounge'] || {}}
        />
        <HomeSectionGalleries id="galleries" content={pageContent.galleries} />
        <HomeSectionEventPromo id="event-promo" content={pageContent.event} />
        <HomeSectionHiring content={pageContent.copyWriting['Hiring']} />
        {/* <HomeSectionPrivateEvents
          id="private-events"
          eventPrivateContent={{
            backgroundUrl: pageContent.private_event_background,
            mobileBackgroundUrl: pageContent.private_event_mobile_background,
            description: pageContent.private_event_description,
          }}
        />   */}
      </div>
      <Footer
        id="footer"
        content={pageContent.footer}
        logo={pageContent.logo}
      />
    </PageSpinner>
  );
}

export default { Home };
