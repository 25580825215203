import React, { useEffect, useRef } from 'react';

import mapboxgl from 'mapbox-gl';
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker';

import './style.scss';
import { message } from 'antd';
mapboxgl.workerClass = MapboxWorker;
mapboxgl.accessToken =
  'pk.eyJ1IjoicHQtbXNnIiwiYSI6ImNsNmFpNHRibTA1ZjczY29idnpmcXllbWYifQ.c62OyMVc8usf9kgOPbo4ZQ';

const MapComponent = ({ lat = 40, lng = -74.5 }) => {
  const mapContainer = useRef(null);
  const map = useRef(null);
  useEffect(() => {
    if (map.current) return;
    try {
      map.current = new mapboxgl.Map({
        container: mapContainer.current, // container ID
        style: 'mapbox://styles/mapbox/streets-v11', // style URL
        center: [lng, lat], // starting position [lng, lat]
        zoom: 5, // starting zoom
        projection: 'globe', // display the map as a 3D globe
      });

      new mapboxgl.Marker().setLngLat([lng, lat]).addTo(map.current);
    } catch (_) {
      message.error('Invalid latitude or longitude');
    }
    // map.on('load', () => {
    //   map.resize();
    // });
    // new mapboxgl.Marker().setLngLat([lat,lng]).addTo(map);
  }, [lat, lng]);

  return <div ref={mapContainer} className="map-container" />;
};

//

export default MapComponent;
