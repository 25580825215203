import React, { useEffect, useState } from 'react';
import { message, Col, Row } from 'antd';
import axios from 'axios';

import HtmlRender from 'components/base/html-reader';

import HiringApi from 'api/hiring';
import HiringModal from './hiring-modal';
import './style.scss';

export default function SectionExperience({ content = {}, id = '' }) {
  const [hiringForm, setHiringForm] = useState({});
  const [isHiringModalOpen, setIsHiringModalOpen] = useState(false);
  const [ableToSubmit, setAbleToSubmit] = useState(false);
  const [uploadedFile, setUploadedFile] = useState({});
  const [uploadLoading, setUploadLoading] = useState(false);

  useEffect(() => {
    const isAbleToSubmit = () => {
      if (hiringForm.name && hiringForm.email && uploadedFile.name) {
        setAbleToSubmit(true);
      }
    };

    isAbleToSubmit();
  }, [hiringForm, uploadedFile]);

  const onOpenHiringModal = () => {
    setIsHiringModalOpen(true);
  };

  const onCloseHiringModal = () => {
    setIsHiringModalOpen(false);
  };

  const onUploadHiring = () => {};

  const onChangeHiringForm = ({ target }) => {
    const { value, name } = target;
    setHiringForm((prevState) => ({ ...prevState, [name]: value }));
  };

  const onChangeFileUpload = (file) => {
    if (file.size > 5000) {
      message.error('Max 5Mb');
    }
    setUploadedFile(file.file);
  };

  const onSubmitHiringForm = async () => {
    setUploadLoading(true);
    const fileFormData = new FormData();
    fileFormData.append('file', uploadedFile);

    try {
      const { data = {} } = await axios({
        method: 'post',
        url: 'https://api.despectra.com/wp-json/wp/v2/media',
        data: fileFormData,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization:
            'Basic ZGVzcGVjdHJhY21zYWRtaW46d2UwQSBucTBXIDRCakIgZjd5OCBCdHl0IEY2TUs=',
        },
      });

      const { source_url = '' } = data;

      const obj = {
        full_name: hiringForm.name,
        email: hiringForm.email,
        resume: source_url,
      };

      await HiringApi.postHiring(obj);
      message.success('File sent!');
      onCloseHiringModal();
    } catch (_) {
      message.error('Error, please try again.');

      setUploadLoading(false);
    } finally {
      setUploadLoading(false);
      onCloseHiringModal();
    }
  };

  return (
    <section
      id={id}
      style={{
        background: `url(${content.image}) no-repeat`,
      }}
      className="pd-home-about-us">
      <HiringModal
        visibility={isHiringModalOpen}
        onCancel={onCloseHiringModal}
        onSubmit={onSubmitHiringForm}
        onUpload={onUploadHiring}
        onChange={onChangeHiringForm}
        ableToSubmit={ableToSubmit}
        onChangeFileUpload={onChangeFileUpload}
        uploadedFile={uploadedFile}
        uploadLoading={uploadLoading}
      />

      <Row type="flex" align="middle" style={{ height: '100%' }}>
        <Col xs={24} sm={{ span: 24, offset: 22 }}>
          <div className="text-container">
            <div className="title-2 gold">
              <strong>WE ARE HIRING!</strong>
              <br />
              <strong>JOIN OUR TEAM.</strong>
            </div>
            <div className="description white">
              <HtmlRender content={content.content} />
            </div>
            <div>
              <button className="join-us-btn" onClick={onOpenHiringModal}>
                Join Us
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  );
}
