import React from 'react';
import { Icon, Row, Col } from 'antd';

import Container from 'components/base/container';
import MapComponent from 'components/base/map';

import './style.scss';

export function Footer({ content = {}, logo = '', id = '' }) {
  const currentYear = new Date().getFullYear();
  const {
    instagram_url = '',
    latitude = 0,
    location = '',
    longitude = 0,
    phone_number = '',
    twitter_url = '',
    facebook = '',
  } = content;

  return (
    <footer className="pd-footer" id={id}>
      <Container>
        <Row type="flex" align="middle">
          <Col xs={24} lg={10}>
            <div className="pd-footer-logo-wrapper">
              <img src={logo} className="pd-footer-logo" alt="Spectra" />
            </div>
            <ul className="list">
              <li>
                <div className="title-2 gold">LOCATION</div>
              </li>
              <li>
                <p>{location}</p>
              </li>
              <li>
                <div className="title-2 gold">RESERVATION</div>
              </li>
              <li>
                <a
                  href={`https://wa.me/${phone_number}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ cursor: 'pointer' }}>
                  <p>{phone_number}</p>
                </a>
              </li>

              <li className="social-media pd-margin-top-md">
                <div className="list">
                  <a href={facebook} target="_blank" rel="noopener noreferrer">
                    <Icon type="facebook" theme="filled" />
                  </a>
                </div>
                <div className="list">
                  <a
                    href={twitter_url}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Icon type="twitter" />
                  </a>
                </div>
                <div className="list">
                  <a
                    href={instagram_url}
                    target="_blank"
                    rel="noopener noreferrer">
                    <Icon type="instagram" />
                  </a>
                </div>
              </li>
            </ul>
          </Col>
          <Col xs={24} lg={{ span: 10, offset: 4 }}>
            <MapComponent
              lat={parseFloat(latitude)}
              lng={parseFloat(longitude)}
            />
          </Col>
        </Row>
      </Container>
      <Row>
        <Col xs={24}>
          <div className="description gold pd-align-center">
            © {currentYear} DeSpectra. All rights reserved.
          </div>
        </Col>
      </Row>
    </footer>
  );
}

export default { Footer };
