import React from 'react';

import { Button, Upload, Form, Input, Modal, Row, Col } from 'antd';

import { CloseSquareOutlined, CheckSquareOutlined } from '@ant-design/icons';

import './style.scss';
const HiringModal = ({
  visibility,
  onChange,
  onCancel,
  onUpload,
  onSubmit,
  ableToSubmit,
  onChangeFileUpload,
  uploadedFile,
  uploadLoading = false,
}) => {
  return (
    <Modal
      // title="WE ARE HIRING!"
      bodyStyle={{ backgroundColor: 'black' }}
      visible={visibility}
      onCancel={onCancel}
      footer={[]}>
      <h1 className="color-green">WE ARE HIRING!</h1>
      <p className="color-white pd-margin-bottom-sm">
        If you are passionate about a career at DeSpectra, please fill out your
        information below.
      </p>

      <Form onSubmit={onSubmit}>
        <Form.Item>
          <Input
            size="large"
            onChange={onChange}
            style={{ backgroundColor: 'black', color: 'white' }}
            placeholder="Full Name"
            type="string"
            className="hiring-input"
            required
            name="name"
          />
        </Form.Item>
        <Form.Item>
          <Input
            size="large"
            onChange={onChange}
            style={{ backgroundColor: 'black', color: 'white' }}
            placeholder="Email"
            type="text"
            className="hiring-input"
            required
            name="email"
          />
        </Form.Item>
        <Row justify="end" gutter={[8, 8]}>
          <p className="color-white">
            Please attach your resume{' '}
            {uploadedFile.name ? (
              <CheckSquareOutlined style={{ color: 'white' }} />
            ) : (
              <CloseSquareOutlined style={{ color: 'white' }} />
            )}
          </p>

          <Col xs={{ span: 24 }} md={{ span: 6, offset: 12 }}>
            <Form.Item>
              <Upload
                accept="application/pdf"
                customRequest={onChangeFileUpload}
                className="upload-button"
                key="upload"
                onClick={onUpload}
                showUploadList={false}>
                <Button className="upload-button" loading={uploadLoading}>
                  Upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 6 }}>
            <Form.Item>
              <Button
                className="submit-button"
                key="Submit"
                type="submit"
                onClick={onSubmit}
                disabled={!ableToSubmit}>
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>

        {/* <input className="color-white" type="text" name="name" required />
        <input className="color-white" type="email" name="email" required /> */}
      </Form>
    </Modal>
  );
};

export default HiringModal;
