import React from 'react';
import { Col, Row } from 'antd';

import HtmlRender from 'components/base/html-reader';

import './style.scss';

export default function SectionExperience({ content = {}, id = '' }) {
  return (
    <section
      id={id}
      className="pd-home-about-us"
      style={{
        background: `url(${content.image}) no-repeat`,
      }}>
      <Row type="flex" align="middle" style={{ height: '100%' }}>
        <Col xs={24} sm={{ span: 8, offset: 14 }}>
          <div className="text-container">
            <div className="title-2 gold">
              <strong>About Us</strong>
            </div>
            <div className="description white">
              <HtmlRender content={content.content} />
            </div>
          </div>
        </Col>
      </Row>
    </section>
  );
}
