import api from './index';

export default {
  postHiring(payload) {
    return api.post(`/hiring`, { fields: payload });
  },
  postMedia(payload) {
    return api.post('/media', payload);
  },
};
